<template>
    <div>
        <div class="modal-backdrop fade show"></div>

        <div class="modal fade show" id="share-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeModal">
                    <div class="modal-body pb-5 pt-4" style="border-radius: 10px">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div class="d-flex flex-column mt-5">
                            <p class="text-center mt-5">{{$t('Your job is published.')}}</p>
                            <div class="Share-BG-Image">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                    <g id="Background_Complete">
										<g id="XMLID_68_">
											<path id="XMLID_74_" class="st0" d="M136.31,126.37L136.31,126.37c-18.92,0-34.26-15.34-34.26-34.26l0,0
												c0-18.92,15.34-34.26,34.26-34.26l0,0c18.92,0,34.26,15.34,34.26,34.26l0,0C170.57,111.03,155.23,126.37,136.31,126.37z"/>
                                            <g id="XMLID_69_">
												<g id="XMLID_71_">
													<rect id="XMLID_73_" x="120.77" y="84.47" class="st1" width="7.09" height="22.91"/>
                                                    <path id="XMLID_72_" class="st1" d="M124.28,81.48c2.32,0,4.19-1.89,4.19-4.23c0-2.33-1.88-4.23-4.19-4.23
														c-2.32,0-4.19,1.89-4.19,4.23C120.09,79.58,121.97,81.48,124.28,81.48z"/>
												</g>
                                                <path id="XMLID_70_" class="st1" d="M139.2,95.36c0-3.22,1.48-5.14,4.32-5.14c2.61,0,3.86,1.84,3.86,5.14c0,3.3,0,12.02,0,12.02
													h7.06c0,0,0-8.37,0-14.5c0-6.14-3.48-9.1-8.33-9.1c-4.86,0-6.9,3.78-6.9,3.78v-3.09h-6.8v22.91h6.8
													C139.2,107.38,139.2,98.89,139.2,95.36z"/>
											</g>
										</g>
                                        <polygon class="st2" points="182.22,23.89 186.91,32.46 195.49,37.16 186.91,41.85 182.22,50.42 177.53,41.85 168.95,37.16
											177.53,32.46 	"/>
                                        <polygon class="st0" points="377.87,256.27 381.39,262.71 387.82,266.22 381.39,269.74 377.87,276.18 374.35,269.74 367.92,266.22
											374.35,262.71 	"/>
                                        <polygon class="st3" points="387.82,224.51 389.41,227.41 392.31,229 389.41,230.59 387.82,233.49 386.23,230.59 383.33,229
											386.23,227.41 	"/>
                                        <polygon class="st3" points="148.71,27.11 150.3,30.01 153.2,31.6 150.3,33.19 148.71,36.09 147.13,33.19 144.22,31.6
											147.13,30.01 	"/>
                                        <g>
											<g>
												<path class="st3" d="M69.13,296.76c3.58-1.56,6.09-5.3,6.06-9.67c-0.05-5.73-4.45-10.34-9.82-10.29
													c-5.37,0.04-9.68,4.73-9.63,10.45c0.04,4.41,2.67,8.14,6.32,9.6c-14.94,2.45-14.05,18.71-14.05,18.71l35.39-0.29
													C83.4,315.26,84,298.81,69.13,296.76z"/>
											</g>
										</g>
                                        <g>
											<path class="st0" d="M408.64,403.94c-0.5-2.55-3.01-4.54-5.61-4.44c-2.57,0.09-4.91,2.22-5.26,4.77c-0.37-2.54-2.73-4.65-5.3-4.72
												c-2.59-0.07-5.09,1.94-5.57,4.49c-0.35,1.86,0.29,3.79,1.33,5.37c1.05,1.57,2.47,2.85,3.89,4.11c1.98,1.76,3.81,3.41,5.78,5.18
												c1.96-1.78,3.63-3.45,5.59-5.23c1.4-1.27,2.82-2.56,3.85-4.14C408.38,407.73,409,405.79,408.64,403.94z"/>
										</g>
                                        <g>
											<g>
												<path class="st3" d="M431.69,193.15l3.79-0.75l5.99-13.18l-1.5-7.57c0,0,5.79-1.52,7.07,8.53c1.28,10.05,1.52,7.38,1.52,7.38
													s12.46-2.42,13.71-1.54c0.91,0.64,3.7,12.93,5.16,19.61c0.73,3.36-0.71,6.91-3.69,8.33c-0.42,0.2-0.86,0.36-1.3,0.45
													c-3.1,0.62-19.47,1.24-19.47,1.24l-6.55,1.3L431.69,193.15z"/>
											</g>
                                            <g>
												<polygon class="st3" points="420.42,193.23 427.9,218.7 434.39,217.41 429.35,192 			"/>
											</g>
										</g>
									</g>
                                    <g id="Background_Simple" class="st4">
										<path class="st5" d="M469.9,186.18c-2.59-27.48-8.96-52.63-22.48-73.47c-13.53-20.84-39.62-31.82-63.71-37.88
											c-24.09-6.06-44.24-0.34-61.72,17.32c-18.3,18.49-23.86,46.94-28.96,72.45c-5.1,25.51-15.05,58.57-35.76,74.3
											c-23.61,17.93-60.7,8.24-90.01,3.76c-29.3-4.48-61.75-8.79-86.46,7.59c-20.26,13.44-30.51,38.99-29.28,63.28
											c1.23,24.28,8.73,46.73,20.95,67.76c16.84,28.96,66.47,64.17,99.89,53.48l144.13-8.12c27.17-12.47,20.96,6.77,65.34-19.05
											c25.84-15.03,65.68-69.46,77.39-106.91C469.49,267.83,473.12,220.44,469.9,186.18z"/>
                                        <path class="st5" d="M182.86,34.82l-9.17-3.81c-36.06-12.22-79.95,8.55-93.37,44.18c-13.42,35.63,5.87,80.19,41.03,94.79
											c35.16,14.6,80.34-3.19,96.11-37.84C233.23,97.49,216.97,51.74,182.86,34.82z"/>
									</g>
                                    <g id="Floor">
									</g>
                                    <g id="Icons">
										<g id="XMLID_45_">
											<path id="XMLID_47_" class="st6" d="M393.43,183.15L393.43,183.15c-20.18,9.81-44.49,1.41-54.31-18.77l0,0
												c-9.81-20.18-1.41-44.49,18.77-54.31l0,0c20.18-9.81,44.49-1.41,54.31,18.77l0,0C422.01,149.02,413.61,173.34,393.43,183.15z"/>
                                            <path id="XMLID_46_" class="st1" d="M365.62,155.69c0.68-0.04,1.09-0.52,0.95-1.23c-0.1-0.49-0.36-1.73-0.47-2.25
												c-0.14-0.71-0.31-0.89-1.03-1.21c-1.58-0.7-2.97-1.92-4.02-4.09c-3.06-6.3-1.09-14.23,6.47-17.91c6.69-3.26,12.36-0.95,15.02,4.51
												c3.5,7.19,3.26,14.8-1.46,17.1c-2.61,1.27-5.61,0.06-6.27-2.89c-0.78-3.52-0.99-7.64-2.1-9.92c-0.99-2.04-2.92-3.21-5.18-2.11
												c-2.67,1.3-3.47,5.1-1.67,8.79c1.14,2.35,2.71,3.56,2.71,3.56s2.89,12.89,3.4,15.14c1.01,4.49,4.22,9.04,4.53,9.51
												c0.19,0.28,0.6,0.16,0.66-0.14c0.09-0.44,1.36-5.91,0.53-10.35c-0.23-1.26-1.58-7.7-1.58-7.7c1.67,1.23,4.91,1.45,7.6,0.14
												c8.01-3.9,9.9-13.85,5.14-23.62c-3.6-7.39-13.21-11.23-22.72-6.6c-11.84,5.76-13.69,17.16-10.24,24.24
												C357.97,152.94,361.45,155.97,365.62,155.69z"/>
										</g>
                                        <g id="XMLID_38_">
											<path id="XMLID_44_" class="st7" d="M434.01,297.76L434.01,297.76c-14.46-5.52-21.7-21.72-16.17-36.18l0,0
												c5.52-14.46,21.72-21.7,36.18-16.17l0,0c14.46,5.52,21.7,21.72,16.17,36.18l0,0C464.67,296.04,448.47,303.28,434.01,297.76z"/>
                                            <g id="XMLID_39_">
												<path id="XMLID_41_" class="st8" d="M458.64,276.34c2.7-7.64-1.21-16.09-8.88-19.02c-7.58-2.9-16.02,0.68-19.2,8
													c-0.08,0.2-0.17,0.4-0.25,0.61c-1.01,2.65-1.22,5.4-0.74,8l-5.41,6.53l8.76,0.5c1.6,1.88,3.68,3.4,6.15,4.35
													c7.79,2.98,16.49-0.88,19.44-8.61C458.56,276.58,458.6,276.46,458.64,276.34z M439.92,283.07c-2.41-0.92-4.37-2.49-5.78-4.44
													l-5.11-0.29l3.15-3.8c-0.68-2.48-0.61-5.19,0.38-7.76c0.15-0.38,0.31-0.76,0.5-1.12c2.86-5.73,9.71-8.46,15.85-6.11
													c6.22,2.38,9.51,9.12,7.67,15.36c-0.09,0.31-0.19,0.62-0.31,0.93C453.79,282.33,446.46,285.57,439.92,283.07z"/>
                                                <path id="XMLID_40_" class="st8" d="M449.8,276.6c-0.28-0.3-1.66-1.79-1.94-2.02c-0.27-0.24-0.48-0.38-0.84-0.13
													c-0.36,0.25-1.32,0.77-1.61,0.92c-0.29,0.15-0.5,0.1-0.78-0.2c-0.28-0.3-1.26-1.09-2.13-2.76c-0.68-1.29-0.95-2.68-1.02-3.1
													c-0.07-0.42,0.18-0.54,0.42-0.64c0.22-0.1,0.5-0.27,0.75-0.4c0.07-0.04,0.13-0.08,0.19-0.12c0.13-0.1,0.23-0.19,0.37-0.33
													c0.21-0.19,0.22-0.41,0.2-0.61c-0.02-0.2-0.07-2.16-0.1-2.96c-0.02-0.79-0.36-0.79-0.56-0.87c-0.2-0.08-0.42-0.2-0.65-0.29
													c-0.23-0.09-0.64-0.15-1.09,0.08c-0.45,0.22-1.66,0.71-2.3,2.4c-0.15,0.4-0.23,0.82-0.27,1.24c-0.11,1.34,0.26,2.63,0.33,2.85
													c0.09,0.29,0.94,4.71,3.92,7.44c3,2.71,3.17,2.25,3.83,2.44c0.65,0.19,2.36-0.04,2.95-0.74c0.6-0.69,0.86-1.37,0.83-1.55
													C450.27,277.08,450.08,276.9,449.8,276.6z"/>
											</g>
										</g>
                                        <g id="XMLID_24_">
											<path id="XMLID_26_" class="st9" d="M69.63,168.24L69.63,168.24c-11.8,0-21.37-9.57-21.37-21.37l0,0
												c0-11.8,9.57-21.37,21.37-21.37l0,0c11.8,0,21.37,9.57,21.37,21.37l0,0C91,158.67,81.43,168.24,69.63,168.24z"/>
                                            <path id="XMLID_25_" class="st1" d="M58.25,154.03c2.07,1.33,4.52,2.1,7.16,2.1c8.67,0,13.57-7.33,13.28-13.9
												c0.91-0.66,1.7-1.48,2.33-2.42c-0.84,0.37-1.74,0.62-2.68,0.74c0.96-0.58,1.71-1.49,2.05-2.58c-0.9,0.54-1.9,0.92-2.97,1.13
												c-0.85-0.91-2.07-1.47-3.41-1.47c-3.01,0-5.23,2.81-4.55,5.74c-3.88-0.19-7.33-2.05-9.63-4.88c-1.22,2.1-0.63,4.85,1.45,6.24
												c-0.77-0.02-1.49-0.24-2.12-0.58c-0.05,2.16,1.5,4.19,3.75,4.64c-0.66,0.18-1.38,0.22-2.11,0.08c0.59,1.86,2.32,3.21,4.36,3.24
												C63.2,153.63,60.72,154.32,58.25,154.03z"/>
										</g>
                                        <g id="XMLID_4_">
											<path id="XMLID_21_" class="st10" d="M108.03,407.61L108.03,407.61c-19.77,4.71-39.62-7.5-44.33-27.27l0,0
												c-4.71-19.77,7.5-39.62,27.27-44.33h0c19.77-4.71,39.62,7.5,44.33,27.27l0,0C140.01,383.06,127.8,402.9,108.03,407.61z"/>
                                            <g id="XMLID_5_">
												<path id="XMLID_14_" class="st1" d="M95.67,355.72c5.24-1.25,5.87-1.38,7.96-1.78c1.93-0.37,3.05-0.3,3.81-0.19
													c1,0.14,1.76,0.41,2.61,0.93c0.85,0.52,1.43,1.08,2.01,1.91c0.43,0.63,1,1.59,1.54,3.48c0.59,2.05,0.76,2.66,2,7.9
													c1.25,5.24,1.38,5.87,1.78,7.96c0.37,1.93,0.3,3.05,0.19,3.81c-0.14,1-0.41,1.76-0.93,2.61c-0.52,0.85-1.08,1.43-1.91,2.01
													c-0.63,0.43-1.59,1-3.48,1.54c-2.05,0.59-2.66,0.75-7.9,2c-5.24,1.25-5.87,1.38-7.96,1.78c-1.93,0.37-3.05,0.3-3.81,0.19
													c-1-0.14-1.76-0.41-2.61-0.93c-0.85-0.52-1.43-1.08-2.01-1.91c-0.43-0.63-1-1.59-1.54-3.48c-0.59-2.05-0.76-2.66-2-7.9
													c-1.25-5.24-1.38-5.87-1.78-7.96c-0.37-1.94-0.3-3.05-0.19-3.81c0.14-1,0.41-1.76,0.93-2.61c0.52-0.85,1.08-1.43,1.91-2.01
													c0.63-0.43,1.59-1,3.48-1.54C89.81,357.14,90.43,356.97,95.67,355.72 M94.83,352.18c-5.33,1.27-5.99,1.45-8.07,2.05
													c-2.07,0.59-3.41,1.27-4.55,2.05c-1.17,0.81-2.11,1.74-2.94,3.09c-0.83,1.35-1.24,2.61-1.44,4.02c-0.19,1.36-0.18,2.87,0.22,4.98
													c0.4,2.12,0.54,2.79,1.81,8.12c1.27,5.33,1.45,5.99,2.05,8.07c0.59,2.07,1.26,3.41,2.05,4.55c0.81,1.17,1.74,2.11,3.09,2.94
													c1.35,0.83,2.61,1.24,4.02,1.44c1.36,0.19,2.87,0.18,4.98-0.22c2.12-0.4,2.79-0.54,8.12-1.81c5.33-1.27,5.99-1.45,8.07-2.05
													c2.07-0.59,3.41-1.27,4.55-2.05c1.17-0.81,2.11-1.74,2.94-3.09c0.83-1.35,1.24-2.61,1.44-4.02c0.19-1.36,0.18-2.87-0.22-4.98
													c-0.4-2.12-0.54-2.79-1.81-8.12c-1.27-5.33-1.45-5.99-2.05-8.07c-0.59-2.07-1.27-3.41-2.05-4.55c-0.81-1.17-1.74-2.11-3.09-2.94
													c-1.35-0.83-2.61-1.24-4.02-1.44c-1.36-0.19-2.87-0.18-4.98,0.22C100.83,350.78,100.16,350.91,94.83,352.18"/>
                                                <path id="XMLID_8_" class="st1" d="M97.1,361.73c-5.57,1.33-9.01,6.91-7.68,12.48c1.33,5.57,6.91,9,12.48,7.68
													c5.57-1.33,9-6.91,7.68-12.48C108.26,363.84,102.67,360.41,97.1,361.73 M101.06,378.36c-3.61,0.86-7.24-1.37-8.1-4.98
													c-0.86-3.61,1.37-7.24,4.98-8.1c3.61-0.86,7.24,1.37,8.1,4.98C106.91,373.87,104.68,377.49,101.06,378.36"/>
                                                <path id="XMLID_7_" class="st1" d="M109.84,358.28c0.31,1.3-0.49,2.61-1.79,2.92c-1.3,0.31-2.61-0.49-2.92-1.79
													c-0.31-1.3,0.49-2.61,1.79-2.92C108.23,356.17,109.53,356.98,109.84,358.28"/>
											</g>
										</g>
                                        <g id="XMLID_1_">
											<path id="XMLID_3_" class="st11" d="M386.81,375.22L386.81,375.22c-9.48,0-17.16-7.68-17.16-17.16l0,0
												c0-9.48,7.68-17.16,17.16-17.16l0,0c9.48,0,17.16,7.68,17.16,17.16l0,0C403.97,367.54,396.29,375.22,386.81,375.22z"/>
                                            <path id="XMLID_2_" class="st1" d="M384.19,368.43h4.18v-10.46h2.91l0.31-3.5h-3.23c0,0,0-1.31,0-2c0-0.83,0.17-1.15,0.96-1.15
												c0.64,0,2.26,0,2.26,0v-3.64c0,0-2.38,0-2.89,0c-3.11,0-4.51,1.37-4.51,3.99c0,2.28,0,2.79,0,2.79h-2.17v3.55h2.17V368.43z"/>
										</g>
									</g>
                                    <g id="Device">
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path class="st12" d="M328.27,470.05l-137.89-0.08c-16.58-0.01-30.02-13.46-30.02-30.04l0.07-345.69
																			c0-16.59,13.46-30.04,30.05-30.03l137.89,0.08c16.58,0.01,30.02,13.46,30.02,30.04l-0.07,345.69
																			C358.32,456.61,344.86,470.06,328.27,470.05z"/>
																	</g>
																</g>
															</g>
                                                            <g>
																<g>
																	<g>
																		<path class="st1" d="M328.23,76.52l-26.73-0.02c-3.58,0-6.49,3.04-6.49,6.78l0,4.85c0,3.75-2.91,6.78-6.49,6.78
																			l-52.11-0.03c-3.58,0-6.49-3.04-6.49-6.79l0-4.85c0-3.75-2.91-6.79-6.49-6.79l-9.4-0.01l-23.4-0.01
																			c-11.62-0.01-21.05,9.41-21.05,21.03l-0.07,337.41c0,11.62,9.41,21.03,21.02,21.04L328.14,456
																			c11.62,0.01,21.04-9.41,21.05-21.03l0.07-337.42C349.25,85.94,339.84,76.52,328.23,76.52z"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
                                                    <g>
														<g>
															<g>
																<g>
																	<path class="st13" d="M330.24,103.93c0,0.09-3.48,0.19-7.76,0.23c-4.29,0.04-7.77-0.01-7.77-0.1c0-0.09,3.47-0.19,7.76-0.23
																		C326.76,103.79,330.24,103.84,330.24,103.93z"/>
																</g>
															</g>
														</g>
                                                        <g>
															<g>
																<g>
																	<path class="st13" d="M330.27,107.9c0,0.09-3.48,0.19-7.76,0.23c-4.29,0.04-7.77-0.01-7.77-0.1c0-0.09,3.47-0.19,7.76-0.23
																		C326.79,107.77,330.27,107.81,330.27,107.9z"/>
																</g>
															</g>
														</g>
                                                        <g>
															<g>
																<g>
																	<path class="st13" d="M330.31,111.87c0,0.09-3.48,0.19-7.76,0.23c-4.29,0.04-7.77-0.01-7.77-0.1c0-0.09,3.48-0.19,7.76-0.23
																		C326.83,111.74,330.31,111.78,330.31,111.87z"/>
																</g>
															</g>
														</g>
													</g>
                                                    <g>
														<g>
															<g>
																<path class="st13" d="M184.02,115.75c0.01-0.01,0.15-0.02,0.41-0.03c0.32-0.01,0.69-0.02,1.13-0.04
																	c1.07-0.03,2.46-0.06,4.18-0.1l-0.12,0.12c-0.01-0.93-0.03-1.94-0.05-3.03c0.01-0.56-0.05-1.09,0.01-1.74
																	c0.11-0.65,0.69-1.22,1.4-1.29c0.67-0.01,1.27-0.04,2-0.01c0.75,0.09,1.39,0.82,1.37,1.61c0.02,1.41,0.03,2.89,0.05,4.42
																	l-0.19-0.19c1.69-0.02,3.46-0.04,5.27-0.05l-0.2,0.2c-0.02-2.12-0.04-4.32-0.06-6.57c0-0.56-0.01-1.12-0.01-1.67
																	c0-0.52-0.32-0.86-0.74-1.25c-0.78-0.77-1.55-1.53-2.3-2.27c-0.75-0.74-1.5-1.48-2.22-2.19c-0.36-0.36-0.72-0.71-1.08-1.06
																	c-0.37-0.36-0.66-0.73-1.09-0.81c-0.42-0.11-0.87-0.02-1.21,0.23c-0.32,0.27-0.66,0.65-0.98,0.96
																	c-0.65,0.65-1.28,1.29-1.89,1.91c-1.19,1.24-2.43,2.37-3.33,3.43c-0.38,0.59-0.24,1.31-0.27,1.97c0,0.65,0,1.27,0,1.86
																	c-0.01,1.14-0.01,2.14-0.02,2.99c-0.01,0.76-0.02,1.4-0.02,1.91C184.04,115.5,184.03,115.74,184.02,115.75
																	c-0.01,0.01-0.02-0.21-0.04-0.63c-0.01-0.51-0.03-1.13-0.05-1.88c-0.02-0.86-0.04-1.87-0.07-3.02
																	c-0.01-0.6-0.02-1.23-0.04-1.89c-0.01-0.34-0.01-0.68-0.02-1.04c0-0.37,0.09-0.76,0.29-1.1c0.98-1.19,2.14-2.25,3.34-3.52
																	c0.61-0.62,1.23-1.26,1.88-1.92c0.34-0.33,0.61-0.66,1.02-1.01c0.42-0.32,1-0.44,1.53-0.3c0.56,0.12,0.93,0.58,1.27,0.91
																	c0.36,0.35,0.72,0.7,1.08,1.06c0.73,0.71,1.47,1.44,2.23,2.19c0.76,0.74,1.53,1.5,2.31,2.27c0.19,0.2,0.41,0.36,0.59,0.64
																	c0.17,0.26,0.27,0.57,0.28,0.89c0.01,0.56,0.01,1.12,0.02,1.68c0.02,2.25,0.03,4.45,0.05,6.57l0,0.2l-0.2,0
																	c-1.82,0.01-3.58,0.02-5.27,0.03l-0.19,0l0-0.19c-0.01-1.53-0.02-3.01-0.02-4.42c0.02-0.64-0.47-1.2-1.07-1.28
																	c-0.61-0.03-1.32-0.01-1.91-0.01c-0.57,0.05-1.04,0.5-1.14,1.02c-0.05,0.53-0.01,1.15-0.02,1.68c0,1.09,0,2.1,0,3.03l0,0.12
																	l-0.12,0c-1.74-0.01-3.16-0.02-4.25-0.03c-0.43-0.01-0.79-0.01-1.11-0.02C184.14,115.76,184.01,115.76,184.02,115.75z"/>
															</g>
														</g>
													</g>
                                                    <g>
														<g>
															<g>
																<g>
																	<path class="st14" d="M274.98,379.41c0,0.04-0.51,0.07-1.44,0.1c-1.03,0.02-2.35,0.05-3.94,0.08
																		c-3.33,0.05-7.92,0.08-13,0.08c-5.08,0-9.67-0.03-13-0.08c-1.59-0.03-2.91-0.06-3.94-0.08c-0.93-0.03-1.44-0.07-1.44-0.1
																		c0-0.04,0.51-0.07,1.44-0.1c1.03-0.02,2.35-0.05,3.94-0.08c3.33-0.05,7.92-0.08,13-0.08c5.08,0,9.67,0.03,13,0.08
																		c1.59,0.03,2.91,0.06,3.94,0.08C274.47,379.34,274.98,379.37,274.98,379.41z"/>
																</g>
                                                                <g>
																	<path class="st12" d="M332.77,388.45c0,0.14-21.17,0.26-47.28,0.26c-26.12,0-47.28-0.12-47.28-0.26
																		c0-0.14,21.17-0.26,47.28-0.26C311.6,388.19,332.77,388.31,332.77,388.45z"/>
																</g>
                                                                <g>
																	<path class="st12" d="M303.22,396.96c0,0.14-14.55,0.26-32.5,0.26c-17.96,0-32.51-0.12-32.51-0.26s14.55-0.26,32.51-0.26
																		C288.67,396.7,303.22,396.82,303.22,396.96z"/>
																</g>
															</g>
														</g>
                                                        <g>
															<path class="st13" d="M334.64,413.53l-2.94-2.95l-2.8,2.79l0.01-8.43l5.73,0.01L334.64,413.53z M331.7,410.21l0.09,0.09
																l2.59,2.6l0.01-7.7l-5.22-0.01l-0.01,7.55L331.7,410.21z"/>
														</g>
                                                        <g>
															<path class="st13" d="M256.41,412.43l-1.04-0.46c-0.5,0.27-0.94,0.41-1.34,0.41c-1.59,0.01-2.89-1.27-2.9-2.86
																c-0.01-1.59,1.27-2.89,2.86-2.9c1.59-0.01,2.89,1.27,2.9,2.86c0.01,0.71-0.25,1.39-0.72,1.92L256.41,412.43z M253.98,406.81
																c-1.48,0.01-2.67,1.22-2.66,2.7c0.01,1.48,1.22,2.67,2.7,2.66c0.38,0,0.8-0.14,1.29-0.41l0.04-0.02l0.76,0.34l-0.17-0.75
																l0.03-0.04c0.46-0.5,0.71-1.15,0.7-1.82C256.68,407.99,255.46,406.8,253.98,406.81z"/>
														</g>
                                                        <g>
															<path class="st13" d="M263.07,411.98l-1.19-2.9l-2.23-2.03l5.4-0.04L263.07,411.98z M260.16,407.25l1.9,1.74l1.01,2.47
																l1.7-4.25L260.16,407.25z"/>
														</g>
                                                        <g>

																<rect x="261.78" y="407.95" transform="matrix(0.8382 -0.5454 0.5454 0.8382 -179.9127 209.7816)" class="st13" width="3.52" height="0.2"/>
														</g>
                                                        <g>
															<path class="st6" d="M247.9,407.66c-0.15-0.77-0.92-1.37-1.71-1.34c-0.78,0.03-1.49,0.68-1.59,1.45
																c-0.12-0.77-0.83-1.41-1.61-1.43c-0.79-0.02-1.54,0.6-1.68,1.37c-0.1,0.56,0.09,1.15,0.41,1.63c0.32,0.48,0.75,0.86,1.18,1.24
																c0.6,0.53,1.16,1.03,1.76,1.56c0.59-0.54,1.1-1.05,1.69-1.59c0.42-0.39,0.85-0.78,1.16-1.26
																C247.83,408.81,248.02,408.22,247.9,407.66z"/>
														</g>
                                                        <g>
															<path class="st12" d="M340.66,420.99c0,0-0.01-0.32-0.02-0.94c0-0.63-0.01-1.54-0.02-2.74c-0.01-2.41-0.02-5.96-0.04-10.56
																c-0.02-9.19-0.05-22.56-0.09-39.33l0.17,0.17c-38.48,0.02-95.91,0.05-159.92,0.09h-0.01l0.26-0.26
																c0,18.62-0.01,36.68-0.01,53.57l-0.25-0.25c46.04,0.05,85.98,0.09,114.43,0.12c14.24,0.03,25.59,0.05,33.41,0.07
																c3.9,0.01,6.91,0.03,8.96,0.03c1.02,0.01,1.79,0.01,2.32,0.02C340.39,420.98,340.66,420.99,340.66,420.99
																s-0.25,0.01-0.77,0.02c-0.53,0-1.29,0.01-2.29,0.02c-2.04,0.01-5.03,0.02-8.91,0.03c-7.8,0.02-19.14,0.04-33.35,0.07
																c-28.49,0.03-68.5,0.07-114.61,0.12l-0.25,0v-0.25c0-16.89-0.01-34.95-0.01-53.57v-0.26h0.26h0.01
																c64.01,0.04,121.44,0.07,159.92,0.09h0.17l0,0.17c-0.04,16.82-0.07,30.22-0.09,39.44c-0.02,4.58-0.03,8.12-0.04,10.52
																c-0.01,1.18-0.01,2.09-0.02,2.71C340.67,420.69,340.66,420.99,340.66,420.99z"/>
														</g>
                                                        <g>
															<g>
																<g>
																	<g>

																			<rect x="191.73" y="372.05" transform="matrix(5.681172e-03 -1 1 5.681172e-03 -185.9514 601.3948)" class="st0" width="35.42" height="44.3"/>
                                                                        <g>
																			<path class="st12" d="M231.69,376.62c0,0-0.08,0.01-0.22,0.01c-0.16,0-0.37,0.01-0.63,0.01c-0.58,0-1.38,0.01-2.42,0.01
																				c-2.13,0-5.2,0.01-9.08,0.01c-7.79-0.02-18.83-0.04-31.95-0.06l0.23-0.23c-0.05,10.54-0.11,22.63-0.17,35.41
																				c0.05,0.06-0.49-0.49-0.25-0.26l0.01,0l0.02,0l0.05,0l0.1,0l0.19,0l0.38,0l0.77,0l1.53,0.01
																				c1.02,0.01,2.03,0.01,3.03,0.02c2.01,0.01,4,0.02,5.97,0.04c3.93,0.03,7.77,0.05,11.49,0.07
																				c7.44,0.05,14.42,0.1,20.75,0.15l-0.22,0.22c0.11-10.63,0.2-19.46,0.26-25.65c0.04-3.08,0.07-5.51,0.1-7.19
																				c0.02-0.81,0.03-1.45,0.04-1.9C231.67,376.86,231.69,376.62,231.69,376.62s0.01,0.2,0.02,0.62c0,0.45,0.01,1.07,0.01,1.86
																				c0,1.67,0.01,4.08,0.02,7.14c-0.01,6.23-0.02,15.1-0.03,25.79l0,0.22l-0.22,0c-6.33-0.03-13.31-0.05-20.75-0.09
																				c-3.72-0.02-7.56-0.04-11.49-0.06c-1.97-0.01-3.96-0.02-5.97-0.03c-1.01-0.01-2.02-0.01-3.03-0.02l-1.53-0.01l-0.77,0
																				l-0.38,0l-0.19,0l-0.1,0l-0.05,0l-0.02,0l-0.01,0c0.23,0.23-0.33-0.32-0.27-0.27c0.08-12.79,0.16-24.87,0.23-35.41l0-0.23
																				l0.23,0c13.18,0.12,24.27,0.23,32.1,0.3c3.86,0.05,6.91,0.09,9.03,0.11c1.02,0.02,1.81,0.03,2.37,0.04
																				c0.25,0.01,0.45,0.01,0.6,0.02C231.62,376.61,231.69,376.62,231.69,376.62z"/>
																		</g>
																	</g>
																</g>
															</g>

                                                            <rect x="193.93" y="373.61" transform="matrix(5.683768e-03 -1 1 5.683768e-03 -185.9805 601.3647)" class="st3" width="30.96" height="41.19"/>
                                                            <polygon class="st2" points="188.72,409.56 203.22,390.4 212.06,402.49 218.14,396.09 229.91,409.8 						"/>
                                                            <g>
																<g>
																	<path class="st2" d="M215.93,388.12c-0.01,1.79-1.47,3.23-3.26,3.22c-1.79-0.01-3.23-1.47-3.22-3.26
																		c0.01-1.79,1.47-3.23,3.26-3.22C214.49,384.87,215.94,386.33,215.93,388.12z"/>
																</g>
															</g>
														</g>
													</g>
                                                    <g>
														<g>
															<g>
																<g>
																	<g>
																		<path class="st12" d="M262.09,146.87c0,0.02-0.16,0.04-0.47,0.05c-0.37,0.01-0.82,0.03-1.36,0.05
																			c-1.26,0.02-2.95,0.05-4.99,0.08c-4.21,0.05-10.03,0.08-16.46,0.08c-6.43,0-12.25-0.03-16.46-0.08
																			c-2.04-0.03-3.73-0.06-4.99-0.08c-0.54-0.02-0.99-0.04-1.36-0.05c-0.31-0.02-0.47-0.03-0.47-0.05
																			c0-0.02,0.16-0.04,0.47-0.05c0.37-0.01,0.82-0.03,1.36-0.05c1.26-0.02,2.95-0.05,4.99-0.08
																			c4.21-0.05,10.03-0.08,16.46-0.08c6.43,0,12.25,0.03,16.46,0.08c2.04,0.03,3.73,0.06,4.99,0.08
																			c0.54,0.02,0.99,0.04,1.36,0.05C261.92,146.84,262.09,146.85,262.09,146.87z"/>
																	</g>
																</g>
															</g>
                                                            <g>
																<g>
																	<path class="st13" d="M327.53,342.65l-4.37-4.38l-4.16,4.15l0.02-12.54l8.52,0.01L327.53,342.65z M323.17,337.73l0.14,0.14
																		l3.84,3.86l0.02-11.44l-7.75-0.01l-0.02,11.22L323.17,337.73z"/>
																</g>
                                                                <g>
																	<path class="st13" d="M216.67,341.33l-2.04-0.91c-0.99,0.53-1.85,0.8-2.64,0.8c-3.13,0.02-5.69-2.5-5.71-5.62
																		c-0.03-3.13,2.5-5.69,5.62-5.71c3.13-0.03,5.69,2.5,5.71,5.62c0.01,1.39-0.49,2.73-1.41,3.78L216.67,341.33z M211.9,330.28
																		c-2.91,0.02-5.26,2.41-5.24,5.32c0.02,2.91,2.41,5.26,5.32,5.24c0.75-0.01,1.58-0.27,2.54-0.8l0.08-0.05l1.5,0.67
																		l-0.34-1.48l0.07-0.07c0.9-0.99,1.39-2.26,1.38-3.59C217.2,332.61,214.81,330.26,211.9,330.28z"/>
																</g>
                                                                <g>
																	<path class="st13" d="M229.79,340.46l-2.34-5.71l-4.39-4l10.64-0.09L229.79,340.46z M224.06,331.13l3.74,3.43l1.99,4.86
																		l3.34-8.36L224.06,331.13z"/>
																</g>
                                                                <g>

																		<rect x="227.24" y="332.52" transform="matrix(0.8382 -0.5453 0.5453 0.8382 -144.1201 179.6392)" class="st13" width="6.92" height="0.39"/>
																</g>
                                                                <g>
																	<path class="st6" d="M199.93,331.95c-0.3-1.52-1.81-2.7-3.36-2.64c-1.53,0.06-2.93,1.34-3.13,2.86
																		c-0.23-1.52-1.64-2.77-3.18-2.81c-1.55-0.04-3.03,1.17-3.32,2.7c-0.2,1.11,0.18,2.26,0.81,3.2c0.63,0.94,1.48,1.7,2.33,2.44
																		c1.19,1.05,2.28,2.03,3.47,3.08c1.17-1.07,2.16-2.07,3.33-3.13c0.83-0.76,1.68-1.53,2.29-2.48
																		C199.79,334.21,200.15,333.05,199.93,331.95z"/>
																</g>
															</g>
                                                            <circle class="st0" cx="198.96" cy="146.87" r="7.22"/>
														</g>
                                                        <g>
															<g>
																<path class="st12" d="M324.81,301.25c0,0.14-30.26,0.26-67.58,0.26c-37.33,0-67.59-0.12-67.59-0.26s30.25-0.26,67.59-0.26
																	C294.55,300.99,324.81,301.11,324.81,301.25z"/>
															</g>
                                                            <g>
																<path class="st12" d="M324.81,290c0,0.14-30.26,0.26-67.58,0.26c-37.33,0-67.59-0.12-67.59-0.26s30.25-0.26,67.59-0.26
																	C294.55,289.74,324.81,289.86,324.81,290z"/>
															</g>
                                                            <g>
																<path class="st12" d="M263.21,312.66c0,0.14-16.47,0.26-36.78,0.26c-20.32,0-36.79-0.12-36.79-0.26s16.47-0.26,36.79-0.26
																	C246.74,312.4,263.21,312.52,263.21,312.66z"/>
															</g>
														</g>
													</g>
                                                    <g>
														<g>
															<path class="st13" d="M254.14,435.29c-0.01,1.61-1.3,2.91-2.88,2.9c-1.59-0.01-2.87-1.32-2.86-2.93
																c0.01-1.61,1.3-2.91,2.88-2.9C252.86,432.37,254.14,433.68,254.14,435.29z"/>
														</g>
                                                        <g>
															<path class="st13" d="M263.03,435.33c-0.01,1.61-1.3,2.91-2.88,2.9c-1.59-0.01-2.87-1.32-2.86-2.93
																c0.01-1.61,1.3-2.91,2.88-2.9C261.76,432.41,263.04,433.72,263.03,435.33z"/>
														</g>
                                                        <g>
															<path class="st13" d="M271.93,435.37c-0.01,1.61-1.3,2.91-2.88,2.9c-1.59-0.01-2.87-1.32-2.86-2.93
																c0.01-1.61,1.3-2.91,2.88-2.9C270.66,432.45,271.94,433.76,271.93,435.37z"/>
														</g>
													</g>
												</g>
                                                <g>
													<rect x="181.69" y="161.15" class="st15" width="159.93" height="97.8"/>
                                                    <polygon class="st2" points="196.46,258.98 242.29,197.64 270.7,236 289.95,215.48 327.72,258.94 				"/>
                                                    <g>
														<g>
															<path class="st2" d="M282.74,190.11c0,5.71-4.62,10.34-10.33,10.34c-5.71,0-10.34-4.62-10.34-10.33
																c0-5.71,4.62-10.34,10.33-10.34C278.11,179.78,282.74,184.4,282.74,190.11z"/>
														</g>
													</g>
												</g>
                                                <g>
													<path class="st3" d="M341.19,349.85c0-2.09-0.1-90.45-0.24-221.24l0.24,0.24c-48.23,0.01-102.59,0.02-159.91,0.02h-0.02
														l0.26-0.26c-0.02,82.74-0.04,159.31-0.05,221.24l-0.21-0.21C278.25,349.77,339.45,349.85,341.19,349.85
														c-1.74,0-62.94,0.08-159.93,0.21l-0.21,0l0-0.21c-0.01-61.93-0.03-138.5-0.05-221.24l0-0.26h0.26h0.02
														c57.32,0.01,111.68,0.02,159.91,0.02l0.24,0l0,0.24c-0.05,65.46-0.09,120.8-0.12,159.77c-0.03,19.46-0.05,34.84-0.06,45.35
														c-0.01,5.24-0.02,9.28-0.03,12c-0.01,1.35-0.01,2.38-0.01,3.08C341.2,349.51,341.19,349.85,341.19,349.85z"/>
												</g>
											</g>
										</g>
									</g>
                                    <g id="Megaphone">
										<g>
											<path class="st16" d="M266.04,377.3l3.54-11.89c0.62-2.58,1.44-5.62,2.54-9.09c0.99-3.12,3.54-6.4,5.86-8.76
												c3.61-3.67,5.33-3.7,7.76-5.65c0.22-0.14,0.47-0.34,0.68-0.46c0.5-0.27,0.87-0.63,1.18-1.02c2.09-2.26,0.08-5.76-4.17-5.43
												c-3.2,0.25-8.41,1.17-16.9,9.92c-0.11,0.13-0.22,0.24-0.32,0.38c-4.45,5.79-8.63-10.73-9.39-16.88c-0.49-3.99-4.8-6.44-8.71-5
												l-20.66,9.66c-1.71-0.6-3.73-0.5-6.03,0.2c-3.04,1.12-4.73,4.25-3.94,7.29l8.38,33.45l-24.61,81.89h53.05L266.04,377.3z"/>
										</g>
                                        <g>
											<g>
												<g>
													<g>
														<path class="st11" d="M306.8,302.08l-16.4,32.13l-8.2-2.28l-41.22,33.53c-3.51,2.85-8.65,2.4-11.6-1.03l-15.69-18.24
															c0,0,31.64-13.63,37.49-24.91c5.85-11.28-0.91-13.84-0.91-13.84l14.89-16.92L306.8,302.08z"/>
													</g>
												</g>
                                                <g>
													<path class="st12" d="M285.53,236.44c0,0-24-5.31-57.78-37.79c-33.78-32.48-49.65-75.62-53.79-75.41
														c-4.13,0.21-22.19-8.17-56.52,66.52c-34.34,74.69-20.81,92.04-13.98,91.9c6.83-0.14,53.23-13.07,82.2-9.78
														c28.98,3.29,66.23,18.4,66.23,18.4L285.53,236.44z"/>
												</g>
                                                <g>
													<path class="st6" d="M336.52,277.15l-8.19,15.57l-3.67,6.97c-5.03,9.57-16.67,13.52-26.49,9l-51.45-17.93l29.96-68.4l51.4,27.39
														C337.96,255.02,341.72,267.26,336.52,277.15z"/>
												</g>
                                                <g>
													<path class="st11" d="M336.52,277.15l-8.19,15.57l-3.67,6.97c-5.03,9.57-16.67,13.52-26.49,9l-51.45-17.93l29.96-68.4
														l51.4,27.39C337.96,255.02,341.72,267.26,336.52,277.15z"/>
												</g>
                                                <g>
													<path class="st6" d="M120.21,191.12c-18.27,40.46-25.88,76.52-16.99,80.53c8.89,4.02,30.91-25.53,49.18-65.99
														c18.27-40.46,25.88-76.51,16.99-80.53C160.5,121.12,138.48,150.66,120.21,191.12z"/>
												</g>
                                                <g>
													<path class="st11" d="M120.21,191.12c-18.27,40.46-25.88,76.52-16.99,80.53c8.89,4.02,30.91-25.53,49.18-65.99
														c18.27-40.46,25.88-76.51,16.99-80.53C160.5,121.12,138.48,150.66,120.21,191.12z"/>
												</g>
											</g>
                                            <g>
												<polygon class="st12" points="340.94,299.65 326.99,295.27 335.62,278.86 346.08,285.37 			"/>
											</g>
                                            <g class="st17">
												<polygon points="335.62,278.86 326.99,295.27 329.86,296.17 336.87,279.64 			"/>
											</g>
                                            <g>
												<path class="st12" d="M326.01,282.65c-0.2,0.45-7.16-2.25-15.54-6.04c-8.39-3.79-15.01-7.22-14.81-7.68
													c0.2-0.45,7.16,2.25,15.54,6.04C319.59,278.76,326.21,282.2,326.01,282.65z"/>
											</g>
                                            <g>
												<path class="st12" d="M330.3,272.88c-0.22,0.45-6.52-2.21-14.07-5.93c-7.55-3.72-13.5-7.09-13.28-7.54
													c0.22-0.44,6.51,2.21,14.07,5.93C324.57,269.06,330.52,272.43,330.3,272.88z"/>
											</g>
                                            <g>
												<path class="st12" d="M320.6,292.9c-0.07,0.24-1.73-0.14-4.37-0.96c-1.32-0.41-2.88-0.92-4.62-1.52
													c-1.72-0.62-3.5-1.53-5.42-2.39c-7.63-3.54-13.45-7.01-13.26-7.42c0.23-0.49,6.45,2.29,14.01,5.79c1.9,0.86,3.65,1.81,5.31,2.5
													c1.66,0.67,3.16,1.3,4.4,1.85C319.15,291.86,320.67,292.66,320.6,292.9z"/>
											</g>
                                            <g>
												<path class="st12" d="M157.46,182.13c0,0-20.86-12.94-32.28,15.25c-11.41,28.19,10.54,32.82,10.54,32.82
													C144.71,216.27,152,200.3,157.46,182.13z"/>
											</g>
                                            <g class="st18">
												<path d="M179.65,148.53c0.12,0.02-0.17,1.94-0.82,5.39c-0.66,3.44-1.59,8.44-2.93,14.58c-0.88,3.02-1.85,6.32-2.88,9.86
													c-0.52,1.77-1.06,3.61-1.62,5.49c-0.28,0.94-0.56,1.9-0.85,2.87c-0.31,0.96-0.72,1.91-1.08,2.88c-1.5,3.88-3.08,7.95-4.71,12.16
													c-0.41,1.05-0.82,2.12-1.24,3.19c-0.41,1.08-0.98,2.09-1.47,3.15c-1.02,2.1-2.05,4.24-3.1,6.39c-1.05,2.16-2.09,4.29-3.11,6.39
													c-0.53,1.04-0.98,2.12-1.57,3.1c-0.59,0.99-1.16,1.97-1.74,2.95c-2.3,3.89-4.53,7.65-6.65,11.23c-0.54,0.89-1.03,1.79-1.6,2.63
													c-0.59,0.83-1.16,1.65-1.73,2.45c-1.14,1.61-2.25,3.17-3.32,4.68c-2.15,3-4.15,5.81-5.98,8.37c-3.99,4.83-7.33,8.65-9.63,11.29
													c-2.31,2.64-3.64,4.05-3.73,3.98c-0.1-0.08,1.05-1.65,3.19-4.42c2.23-2.82,5.35-6.79,9.19-11.65c1.76-2.59,3.69-5.41,5.76-8.44
													c1.04-1.51,2.11-3.08,3.22-4.69c0.55-0.8,1.12-1.62,1.69-2.45c0.55-0.84,1.03-1.75,1.55-2.63c2.08-3.57,4.26-7.32,6.51-11.2
													c0.57-0.97,1.14-1.95,1.71-2.94c0.59-0.98,1.02-2.05,1.55-3.09c1.01-2.09,2.04-4.21,3.08-6.35c1.04-2.14,2.07-4.26,3.09-6.34
													c0.49-1.05,1.06-2.06,1.47-3.12c0.42-1.06,0.84-2.12,1.25-3.16c1.66-4.17,3.25-8.2,4.78-12.04c0.37-0.96,0.79-1.9,1.11-2.85
													c0.3-0.96,0.59-1.91,0.88-2.84c0.58-1.87,1.15-3.68,1.7-5.43c1.1-3.5,2.13-6.76,3.07-9.75c1.45-6.01,2.63-10.91,3.47-14.4
													C179,150.38,179.53,148.51,179.65,148.53z"/>
											</g>
										</g>
                                        <g class="st19">
											<path d="M218.69,343.96c1.9,3.45,3.85,6.96,6.66,9.72c2.82,2.76,6.68,4.72,10.61,4.32c3.92-0.4,7.64-3.65,7.68-7.6
												c1.88,1.42,4.77,0.65,6.33-1.12c1.56-1.77,2.07-4.22,2.25-6.57c2.56,1.51,6.12,0.31,7.82-2.12c1.71-2.43,1.81-5.7,0.99-8.56
												c-0.83-2.85-2.48-5.38-4.11-7.87c1.97,0.16,4.06,0.3,5.82-0.6c1.76-0.89,2.95-3.22,1.92-4.91c-0.6-0.99-1.76-1.48-2.87-1.83
												c-3.09-0.97-5.25-1.74-8.47-1.37c-0.75,0.01,1.29,8.48-18.88,20.06C222.55,342.38,218.69,343.96,218.69,343.96z"/>
										</g>
                                        <g>
											<path class="st20" d="M264.11,356.2c-0.01,0.09-1.36-0.16-3.53-0.11c-2.16,0.05-5.19,0.52-8.18,1.96
												c-2.99,1.43-5.25,3.49-6.65,5.15c-1.41,1.66-2.05,2.87-2.13,2.82c-0.03-0.02,0.1-0.33,0.39-0.88c0.29-0.54,0.77-1.3,1.45-2.18
												c1.34-1.75,3.63-3.91,6.71-5.39c3.07-1.47,6.19-1.9,8.4-1.86c1.11,0.02,2,0.12,2.61,0.23
												C263.79,356.07,264.12,356.16,264.11,356.2z"/>
										</g>
                                        <path class="st16" d="M220.01,338.59c3.25,3.12,5.47,6.57,8.88,9.71c1.55,1.43,3.95,4.13,6.03,4.49c2.08,0.36,3.41,0.08,4.23-1.86
											c0.67-1.57-0.53-3.72-1.35-5.01c-2.17-3.41-4.25-6.07-5.87-8.72c-0.76-1.24,0.22-1.5,0.58-0.92c1.56,1.62,3.9,3.03,5.3,4.79
											c1.92,2.42,2.27,5.62,7.28,4.61c0.66-0.13,1.33-0.36,1.86-0.77c1.89-1.45,1.97-3.96,1.17-5.97c-0.62-1.58-1.72-2.92-2.79-4.23
											c2.14,2.41,4.47,4.95,7.86,5.23c1.97,0.16,3.96-0.76,4.76-2.57c1-2.28-0.44-4.49-1.88-6.27c-2.56-3.16-5.42-6.08-8.53-8.71
											c-1.16-0.98-2.63-1.53-4.15-1.54c-1.76-0.01-3.96,0.32-5.04,1.85c-1.97,2.77-1.36,5.23-1.36,5.23s-4.41-2.23-7.95,0.1
											c-3.54,2.34-4.42,6.35-4.42,6.35L220.01,338.59z"/>
                                        <path class="st16" d="M243.45,319.87c-1.36-1.37-1.68-3.88-0.62-5.96c0.75-1.47,1.69-2.9,2.65-3.22c4.19-1.41,22.5,1.75,22.34,5.82
											c-0.17,4.17-1.5,6.01-11.44,4.57c-1.75-0.25-4.49-0.6-4.49-0.6l-2.4,3.6L243.45,319.87z"/>
                                        <g>
											<path class="st20" d="M246.73,336.89c-0.15,0.09-1.54-2.75-4.38-5.3c-2.82-2.58-5.78-3.69-5.71-3.84
												c0.02-0.06,0.79,0.14,1.94,0.68c1.15,0.54,2.67,1.47,4.12,2.78c1.44,1.31,2.52,2.73,3.16,3.83
												C246.52,336.12,246.79,336.86,246.73,336.89z"/>
										</g>
                                        <g>
											<path class="st20" d="M249.81,324.4c-0.09,0.09-1.3-0.9-2.95-1.91c-0.82-0.5-1.58-0.93-2.15-1.18c-0.57-0.27-0.94-0.34-0.94-0.42
												c0-0.07,0.43-0.12,1.07,0.07c0.64,0.18,1.45,0.57,2.3,1.09C248.83,323.08,249.94,324.29,249.81,324.4z"/>
										</g>
                                        <g>
											<path class="st20" d="M252.89,320.62c-0.01,0.11-1.61,0.16-3.53-0.4c-0.95-0.29-1.76-0.75-2.26-1.18
												c-0.5-0.43-0.71-0.79-0.66-0.83c0.1-0.09,1.23,0.99,3.07,1.51C251.33,320.25,252.91,320.45,252.89,320.62z"/>
										</g>
                                        <g>
											<path class="st20" d="M235.09,341.92c-0.06,0.04-0.71-0.78-1.67-2.17c-0.98-1.35-2.23-3.37-4.27-4.86
												c-2.04-1.49-4.44-1.84-6.05-1.6c-1.64,0.23-2.5,0.91-2.55,0.81c-0.02-0.02,0.17-0.21,0.58-0.46c0.41-0.26,1.06-0.54,1.91-0.71
												c1.68-0.35,4.25-0.03,6.42,1.54c2.16,1.58,3.37,3.68,4.28,5.08C234.61,340.98,235.15,341.88,235.09,341.92z"/>
										</g>
                                        <g>
											<path class="st20" d="M249.1,382.42c0.02,0.14-3.34,0.62-7.48,1.08c-4.15,0.45-7.53,0.7-7.54,0.56c-0.02-0.14,3.33-0.62,7.48-1.08
												C245.71,382.53,249.08,382.28,249.1,382.42z"/>
										</g>
									</g>
                                    <g id="Stars">
										<g>
											<polygon class="st12" points="406.63,49.84 407.76,51.91 409.82,53.04 407.76,54.17 406.63,56.23 405.5,54.17 403.44,53.04
												405.5,51.91 		"/>
                                            <polygon class="st12" points="430.24,101.01 431.37,103.08 433.43,104.21 431.37,105.34 430.24,107.4 429.11,105.34
												427.04,104.21 429.11,103.08 		"/>
                                            <polygon class="st12" points="51.9,237.53 53.03,239.6 55.1,240.73 53.03,241.86 51.9,243.92 50.77,241.86 48.71,240.73
												50.77,239.6 		"/>
                                            <polygon class="st12" points="375.66,62.56 378.27,67.33 383.04,69.94 378.27,72.54 375.66,77.31 373.05,72.54 368.29,69.94
												373.05,67.33 		"/>
                                            <polygon class="st12" points="73.06,205.1 75.66,209.85 80.42,212.45 75.66,215.06 73.06,219.81 70.46,215.06 65.7,212.45
												70.46,209.85 		"/>
                                            <polygon class="st12" points="436.66,318.57 439.26,323.32 444.02,325.92 439.26,328.52 436.66,333.28 434.06,328.52
												429.3,325.92 434.06,323.32 		"/>
										</g>
									</g>
									</svg>
                            </div>
                            <h5 class="text-center text-capitalize mb-30">{{$t('share your job')}}</h5>
                            <div class="share-button-group justify-content-around">
                                <a v-if="job.social_links.facebook"
                                   :href="job.social_links.facebook"
                                   target="_blank"
                                   class="semi-button facebook-button d-flex"
                                >
                                    <span class="icon mr-3"><i class="eicon e-facebook"></i></span>
                                    <span>{{$t('Facebook')}}</span>
                                </a>

                                <a v-if="job.social_links.linkedIn"
                                   :href="job.social_links.linkedIn"
                                   target="_blank"
                                   class="semi-button linkedin-button d-flex"
                                >
                                    <span class="icon mr-3"><i class="eicon e-linkedin"></i></span>
                                    <span>{{$t('LinkedIn')}}</span>
                                </a>

                                <a v-if="job.social_links.twitter"
                                   :href="job.social_links.twitter"
                                   target="_blank"
                                   class="semi-button twitter-button  d-flex"
                                >
                                    <span class="icon mr-3"><i class="eicon e-twitter"></i></span>
                                    <span>{{$t('Twitter')}}</span>
                                </a>

                                <button v-if="job.job_link"
                                        @click.prevent="copyToClipboard(job.job_link)"
                                        class="semi-button primary-button d-flex"
                                >
                                    <span class="icon mr-3"><i class="eicon e-duplicate"></i></span>
                                    <span>{{$t('Copy Link')}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getRequiredFieldData} from "../../../app/api/CommonRequest";
    import client from "../../../app/api/Client";

    export default {
        props: ['job'],
        components: {},
        data() {
            return {}
        },
        methods: {
            closeModal() {
                this.$emit('closeModal');
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .modal-body .share-button-group .semi-button {
        margin-left: 0;
        margin-right: 0;
    }

    .modal-body .share-button-group {
        margin-bottom: 0;
    }

    .st0 {
        fill: #EBEBEB;
    }

    .st1 {
        fill: #FFFFFF;
    }

    .st2 {
        fill: #F5F5F5;
    }

    .st3 {
        fill: #E0E0E0;
    }

    .st4 {
        display: none;
    }

    .st5 {
        display: inline;
        fill: #F5F5F5;
    }

    .st6 {
        fill: #E8505B;
    }

    .st7 {
        fill: #60CE83;
    }

    .st8 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #FFFFFF;
    }

    .st9 {
        fill: #2FC1E1;
    }

    .st10 {
        fill: #F9A458;
    }

    .st11 {
        fill: #3965E2;
    }

    .st12 {
        fill: #597DFC;
    }

    .st13 {
        fill: #263238;
    }

    .st14 {
        fill: #455A64;
    }

    .st15 {
        fill: #F5F7FD;
    }

    .st16 {
        fill: #FFBE9D;
    }

    .st17 {
        opacity: 0.6;
    }

    .st18 {
        opacity: 0.5;
    }

    .st19 {
        opacity: 0.4;
    }

    .st20 {
        fill: #EB996E;
    }
</style>
